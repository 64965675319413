import { Actions } from '@storefront/ui-components/shared/layouts/actions';
import * as Yup from 'yup';
import { Button } from '../buttons';
import { FieldText } from '../forms/fields/FieldText';

import { Link } from '@remix-run/react';
import { Form } from '@storefront/ui-components/shared/forms/Form';
import { useSendEvent } from '@storefront/util/analytics/useAnalytics';
import { useState } from 'react';
import { useStorefrontSettings } from '~/utils/use-storefront-settings';
import { useCustomer } from '../../hooks/useCustomer';

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('This field is required'),
});

interface FooterContentProps {
  isSubscribed: boolean;
}

const ExternalLink = ({ children, to }: { children: React.ReactNode; to?: string }) => {
  if (!to) {
    return null;
  }

  return (
    <Link className="w-fit hover:underline" target="_blank" to={to}>
      {children}
    </Link>
  );
};

const ExternalAnchor = ({ children, href }: { children: React.ReactNode; href?: string }) => {
  if (!href) {
    return null;
  }

  return (
    <span className="w-fit hover:underline">
      <a href={href}>{children}</a>
    </span>
  );
};

export const FooterContent = ({ isSubscribed }: FooterContentProps) => {
  const storefrontSettings = useStorefrontSettings();

  return (
    <>
      <footer className="bg-gray-200 text-sm leading-5 text-gray-800 py-12 px-5 pb-32 md:px-20 md:pb-12">
        <div className="flex justify-between flex-col gap-12 md:flex-row w-full container">
          <div className="flex-grow-[2] flex order-2 w-full md:order-1">
            <div className="flex-auto flex flex-col gap-1 font-normal justify-start">
              <div className="font-semibold mb-2">{storefrontSettings?.name}</div>
              <ExternalLink to={storefrontSettings?.support_links?.terms_of_conditions}>Terms of Service</ExternalLink>

              <ExternalLink to={storefrontSettings?.support_links?.terms_of_sale}>Terms of Sale</ExternalLink>

              <ExternalLink to={storefrontSettings?.support_links?.privacy_policy}>Privacy Policy</ExternalLink>
            </div>
            <div className="flex-auto flex flex-col gap-1">
              <div className="font-semibold mb-2">Help</div>
              <Link className="w-fit hover:underline" target="_blank" to="/faq">
                FAQ's
              </Link>
              <ExternalLink to={storefrontSettings?.support_links?.shipping_policy}>Shipping & Delivery</ExternalLink>

              <ExternalLink to={storefrontSettings?.support_links?.contact_us}>Contact Us</ExternalLink>

              <ExternalAnchor href={`tel:${storefrontSettings?.contact_details?.phone}`}>
                {storefrontSettings?.contact_details?.phone}
              </ExternalAnchor>

              <ExternalAnchor href={`mailto:${storefrontSettings?.contact_details?.email}`}>
                {storefrontSettings?.contact_details?.email}
              </ExternalAnchor>
            </div>
          </div>

          <div className="flex-grow-[1] text-base font-normal order-1 md:order-2">
            <div className="flex flex-col gap-3 md:w-full">
              <div className="text-xl font-normal text-black">Get notified about new listings in your city.</div>
              <div className="flex flex-col items-start gap-5">
                {!isSubscribed ? (
                  <>
                    <FieldText
                      id="footer-subscribe-form-email"
                      name="email"
                      placeholder="Email Address"
                      className="w-full"
                    />
                    <Actions className="!m-0">
                      <Button variant="primary" type="submit">
                        Notify Me
                      </Button>
                    </Actions>
                  </>
                ) : (
                  <div className="text-xl font-normal text-black">Thank you for subscribing!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="container px-5 md:px-20">
        <div className="py-10 text-sm font-medium text-black/60">
          <span>&copy; Ashley Canada, {new Date().getFullYear()}. All rights reserved.</span>
        </div>
      </div>
    </>
  );
};

export const Footer = () => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const subscribeUser = useSendEvent('subscribe');
  const { customer } = useCustomer();

  return (
    <Form
      id="footerForm"
      validationSchema={validationSchema}
      onSubmit={(data: { email: string }, event) => {
        event.preventDefault();
        setIsSubscribed(true);
        subscribeUser({ ...data, ...customer });
      }}
    >
      <FooterContent isSubscribed={isSubscribed} />
    </Form>
  );
};
